import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
    EventEmitter,
    Output,
} from "@angular/core";
import { ModalData } from "../modal/modal.service";
import { ModalHeaderI } from "../modal/modal.header.component";
import { Task } from "../../pojo/Task";
import { EventIncludeOption, EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { jSith } from "../../util/jquery-replacement";
import { TaskService } from "../../services/task/task.service";
import { ContactService } from "../../services/contact.service";
import { UserprefService } from "../../services/userpref.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { S25ItemI } from "../../pojo/S25ItemI";
import { TaskActionCellFactory } from "../../services/task/task.list.service";
import { TaskEditService } from "../../services/task/task.edit.service";
import { Bind } from "../../decorators/bind.decorator";
import { Event } from "../../pojo/Event";

export interface ModalCreateEditTaskModel extends ModalData, ModalHeaderI {
    type: "create" | "edit";
    taskType: Task.Id;
    eventId: number;
    taskId: number;
    listModelBean: any;
    taskActionsCell: any;
    taskCount: number;
    objectType: string;
    objectId: string;
    taskBlocked?: boolean;
    showAllApproveMsg: boolean;
    editCompCallBack?(): void;

    callback(taskId: number, value: any): void;
}

const typeNames: Record<number, string> = {
    [Task.Ids.FYI]: "FYI In Progress",
    [Task.Ids.Authorization]: "Authorization In Progress",
};

@TypeManagerDecorator("s25-ng-create-edit-task-modal")
@Component({
    selector: "s25-ng-create-edit-task-modal",
    template: `
        @if (isInit) {
            <div>
                <s25-modal-header [data]="data"></s25-modal-header>
                @if (isInit) {
                    <div class="modal-body c-padding-bottom--single">
                        @if (inProgress) {
                            <div class="c-modal-flex-container c-modal-event-container">
                                @if (eventName) {
                                    <div class="c-modal-flex-item">
                                        <label class="ngAlignLabelHoriz bold">{{
                                            taskLang.text.associated_event
                                        }}</label>
                                        <div class="ngInlineBlock">{{ eventName }}</div>
                                    </div>
                                }
                                <div class="c-modal-flex-item c-posRelative">
                                    <label for="taskName" class="ngAlignLabelHoriz c-verticalAlign--baseline bold">
                                        {{ taskLang.text.todo_name }}
                                    </label>
                                    @if (!isTodo) {
                                        <span>{{ taskNameBean.value }}</span>
                                    }
                                    @if (isTodo) {
                                        <div class="limit-width">
                                            <s25-ng-editable-text
                                                [(val)]="taskNameBean.value"
                                                (blurred)="taskNameBean.onChange()"
                                            >
                                            </s25-ng-editable-text>
                                        </div>
                                    }
                                </div>
                                @if (isTodo) {
                                    <div class="c-modal-flex-item c-margin-top--single">
                                        <label class="ngAlignLabelHoriz bold">{{ taskLang.text.assigned_by }}</label>
                                        <div class="ngInlineBlock">{{ assignedByName }}</div>
                                    </div>
                                }
                                @if (isCreate) {
                                    <div class="c-modal-flex-item type-create">
                                        <label
                                            for="taskComment"
                                            class="ngAlignLabelHoriz c-verticalAlign--baseline bold"
                                            >{{ taskLang.text.todo_comment }}</label
                                        >
                                        <div class="limit-width">
                                            <s25-ng-editable-textarea
                                                [val]="taskComment"
                                                (valChange)="taskComment = $event"
                                            ></s25-ng-editable-textarea>
                                        </div>
                                    </div>
                                }
                                @if (isCreate) {
                                    <div class="c-margin-top--half c-modal-flex-item">
                                        <label class="ngAlignLabelHoriz c-verticalAlign--baseline bold">{{
                                            taskLang.text.date
                                        }}</label>
                                        <s25-ng-editable-date
                                            class="ngInlineBlock ngTaskModalDate"
                                            [val]="date"
                                            (valChange)="date = $event"
                                            [dateFormat]="dateFormat"
                                            [popoverOnBody]="true"
                                        ></s25-ng-editable-date>
                                    </div>
                                }
                                @if (repeatedElements.length > 1) {
                                    <p>&nbsp;</p>
                                }
                                <div
                                    class="ngModalTaskActionContainer"
                                    s25-infinite-scroll
                                    [onScroll]="this.infiniteScrollAction"
                                    [hasMorePages]="this.scrollHasMorePages"
                                    [topSelector]="'.modal-body'"
                                >
                                    @for (obj of repeatedElements | slice: 0 : actionsShown; track obj) {
                                        <div
                                            class="ngModalTaskActionRow c-modal-flex-container c-padding-bottom--single"
                                        >
                                            @if (data.taskType === 3 || data.taskType === 4) {
                                                <div class="c-modal-flex-item c-margin-top--single">
                                                    <label class="ngAlignLabelHoriz bold">{{
                                                        taskLang.text.event_date
                                                    }}</label>
                                                    <span>{{ obj.eventDate | dateFormat: dateFormat }}</span>
                                                </div>
                                            }
                                            <div class="c-modal-flex-item c-margin-top--single c-posRelative">
                                                <label class="ngAlignLabelHoriz bold">{{
                                                    taskLang.text.todo_comment
                                                }}</label>
                                                <div class="limit-width">
                                                    <s25-ng-editable-textarea
                                                        [val]="obj.comment.value"
                                                        (valChange)="
                                                            obj.comment.value = $event; editTaskComment($event)
                                                        "
                                                        [readOnly]="isCommentreadOnly"
                                                    ></s25-ng-editable-textarea>
                                                </div>
                                            </div>
                                            <div class="c-modal-flex-item c-margin-top--single c-posRelative">
                                                <label class="ngAlignLabelHoriz bold">{{ taskLang.text.date }}</label>
                                                <s25-ng-editable-date
                                                    class="ngInlineBlock"
                                                    [(val)]="obj.dueDate.date"
                                                    (valChange)="editDueDate($event)"
                                                    [dateFormat]="dateFormat"
                                                ></s25-ng-editable-date>
                                            </div>
                                            <div class="c-modal-flex-item c-margin-top--single">
                                                <label class="ngAlignLabelHoriz bold">{{
                                                    taskLang.text.actions
                                                }}</label>
                                                @if (obj.taskAction && obj.taskAction.assignedToId) {
                                                    <div>
                                                        <s25-ng-task-action
                                                            class="ngTaskModalAction"
                                                            [assignedToId]="obj.taskAction.assignedToId"
                                                            [taskState]="obj.taskAction.itemStateId"
                                                            [taskId]="obj.taskAction.itemId"
                                                            [taskBlocked]="obj.taskAction.taskBlocked"
                                                            [itemCount]="obj.taskAction.itemCount"
                                                            [taskType]="obj.taskAction.itemTypeId"
                                                            [requesterId]="obj.taskAction.requesterId"
                                                            [eventId]="obj.taskAction.eventId"
                                                            [todoType]="obj.taskAction.todoType"
                                                            [todoSubType]="obj.taskAction.todoSubType"
                                                            (stateChange)="stateChange($event)"
                                                        ></s25-ng-task-action>
                                                    </div>
                                                }
                                                @if (!obj.taskAction || obj.taskAction.assignedToId === null) {
                                                    <div>None</div>
                                                }
                                            </div>
                                            <!--   [modelBean]="obj.taskAction" -->
                                            <p>&nbsp;</p>
                                        </div>
                                    }
                                </div>
                                <div>
                                    <label class="ngAssignedToContacts bold">{{ taskLang.text.assigned_to }}</label>
                                    @if (isTodo) {
                                        <div class="ngInlineBlock">
                                            <s25-ng-multiselect-search-criteria
                                                [type]="'contacts'"
                                                [selectedItems]="multiSelectBeanTo.selectedItems"
                                                [modelBean]="multiSelectBeanTo"
                                                [popoverOnBody]="true"
                                                [popoverPlacement]="'right'"
                                            ></s25-ng-multiselect-search-criteria>
                                        </div>
                                    }
                                    @if (!isTodo) {
                                        <div>
                                            <s25-ng-task-contacts-picker
                                                [tasks]="taskNode"
                                                (contactsChange)="contactsChange($event)"
                                                [eventId]="data.eventId"
                                                [isOnBody]="true"
                                            ></s25-ng-task-contacts-picker>
                                        </div>
                                    }
                                    <!--notify tasks-->
                                    @if (data.taskType === 1 || data.taskType === 2) {
                                        <div class="task-table--container">
                                            @for (status of approvalState; track status) {
                                                <table class="ngListTbl">
                                                    <tr>
                                                        <th>{{ status.state }}</th>
                                                    </tr>
                                                    @for (approver of status.contacts; track approver) {
                                                        <tr>
                                                            <td>{{ approver.name }}</td>
                                                        </tr>
                                                    }
                                                </table>
                                            }
                                        </div>
                                    }
                                    <!--ap tasks-->
                                    @if (data.taskType === 3 || data.taskType === 4) {
                                        <div class="c-assignedTo-flexWrapper">
                                            @for (contact of taskNode.approval_contact; track contact) {
                                                <span class="c-approvalContact">
                                                    {{ contact.approval_contact_name }}
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        @if (!inProgress) {
                            <div class="ngCenterAlignText ngInline">
                                <div class="ngBold">{{ taskLang.text.success }}</div>
                                <br />
                                <div>{{ successMsg }}</div>
                                <br />
                                <div>
                                    @if (isCreate) {
                                        <button class="btn btn-default" (click)="createAnotherTodo()">
                                            {{ taskLang.text.create_another }}
                                        </button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                <s25-loading-inline [model]="{}"></s25-loading-inline>
                @if (isInit && (isCreate || taskActionAll || resultMsg)) {
                    <div class="modal-footer">
                        @if (resultMsg) {
                            <div>{{ resultMsg }}</div>
                        }
                        @if (taskActionAll && taskActionAll.assignedToId) {
                            <div>
                                <s25-ng-task-action
                                    class="ngTaskModalAction"
                                    [assignedToId]="taskActionAll.assignedToId"
                                    [taskState]="taskActionAll.itemStateId"
                                    [taskId]="taskActionAll.itemId"
                                    [taskBlocked]="taskActionAll.taskBlocked"
                                    [itemCount]="taskActionAll.itemCount"
                                    [taskType]="taskActionAll.itemTypeId"
                                    [requesterId]="taskActionAll.requesterId"
                                    [eventId]="taskActionAll.eventId"
                                    [todoType]="taskActionAll.todoType"
                                    [todoSubType]="taskActionAll.todoSubType"
                                    [objectId]="taskActionAll.objectId"
                                    [objectType]="taskActionAll.objectType"
                                    (stateChange)="stateChange($event)"
                                ></s25-ng-task-action>
                            </div>
                        }
                        @if (isCreate) {
                            <div class="aw-button-group">
                                <button class="aw-button aw-button--outline" (click)="close()" [hidden]="!inProgress">
                                    Cancel
                                </button>
                                <button class="aw-button aw-button--outline" (click)="close()" [hidden]="inProgress">
                                    Close
                                </button>
                                <button
                                    class="aw-button aw-button--primary"
                                    [hidden]="!inProgress"
                                    (click)="createTodo()"
                                >
                                    {{ goText }}
                                </button>
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: `
        .limit-width {
            max-width: 212px;
        }

        .ngModalTaskActionContainer {
            width: 100%;
        }

        ::ng-deep .s25-multiselect-popup-container {
            max-width: 40vw;
            max-height: 80vh;
        }

        .ngAssignedToContacts {
            display: block;
            width: 170px;
            text-align: left;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
})
export class ModalCreateEditTaskComponent implements OnInit, OnChanges {
    @Input() data: ModalCreateEditTaskModel;
    @Output() onStateChange = new EventEmitter<Task.State>();
    isInit: boolean;
    inProgress = true;
    taskLang: any;
    isCreate: boolean;
    isTodo: boolean;
    goText: string;
    successMsg: string;
    multiSelectBeanTo: any;
    eventIncludes: EventIncludeOption[] = ["workflow"];
    taskNameBean: { value: string; isTextarea: boolean; onChange: () => void };
    timeFormat: string;
    dateFormat: string;
    resultMsg: string;
    assignedByName: string;
    assignedById: number;
    eventName: string | boolean;
    assignedToId: number;
    repeatedElements: any[] = [];
    date: Date;
    taskNode: Event.Workflow.Task;
    taskActionAll: any;
    taskComment: string = "";
    userName: string;
    userId: number;
    approvalState: { state: string; contacts: { id: number; name: string }[] }[];
    taskData: TaskData;
    actionsShown: number = 10;
    infiniteScrollAction: () => Promise<void>;
    scrollHasMorePages: () => boolean;
    isCommentreadOnly: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnChanges = (changes: SimpleChanges) => {
        this.isTodo = this.data.taskType === Task.Ids.Todo;
        this.isCreate = this.data.type === "create";
    };

    ngOnInit() {
        this.data.helpLink = "event_todo_create";
        this.isTodo = this.data.taskType === Task.Ids.Todo;
        this.isCreate = this.data.type === "create";

        this.taskLang = S25Util.deepCopy(this.data.lang.div.controls["s25-todo_create"]);
        this.taskLang.text.todo_name = "Task Name:";
        this.taskLang.text.todo_comment = "Comment:";
        this.taskLang.text.event_date = "Event Date:";
        this.taskLang.text.actions = "Actions:";

        // Mock listModelBean unless provided
        this.data.listModelBean ??= {
            editCell: () => {},
            getCell: () => {},
            getRow: () => {},
        };

        this.getTitle(this.data.taskType, this.isCreate);

        if (
            [Task.Ids.Assign, Task.Ids.UnAssign].includes(this.data.taskType) &&
            !this.eventIncludes.includes("reservations")
        )
            this.eventIncludes.push("reservations");

        this.goText = this.isCreate ? this.taskLang.text.create : this.taskLang.text.edit;
        this.successMsg = this.isCreate ? this.taskLang.text.todo_created : this.taskLang.text.todo_updated;

        this.multiSelectBeanTo = {
            singleSelect: true,
            showResult: true,
            buttonText: "Select Contact",
            action: this.editAssignedTo,
            eventId: this.data.eventId,
        };

        this.getData().then((resp) => {
            const { userName, userId, taskData, eventName, dateFormat, timeFormat } = resp;
            this.userName = userName;
            this.userId = userId;
            this.timeFormat = timeFormat;
            this.dateFormat = dateFormat;
            this.taskData = taskData;
            this.taskNameBean = {
                value: S25Util.propertyGet(taskData, "name") || "",
                isTextarea: false,
                onChange: () => this.editTaskName(this.data.taskId, this.taskNameBean.value),
            };

            const inits: any = {
                [Task.Ids.Todo]: () => this.initTodo(eventName, taskData as TodoTaskData),
                [Task.Ids.UnAssign]: () => this.initAssign(taskData as EventTaskData),
                [Task.Ids.Assign]: () => this.initAssign(taskData as EventTaskData),
                [Task.Ids.Authorization]: () => this.initFYI(taskData as EventTaskData),
                [Task.Ids.FYI]: () => this.initFYI(taskData as EventTaskData),
            };
            inits[this.data.taskType]();

            this.initInfiniteScroll();

            this.isInit = true;
            jSith.focusable(this.elementRef.nativeElement, 0, true, true);

            this.changeDetector.detectChanges();
        });
    }

    initTodo = (eventName: string, taskData: TodoTaskData) => {
        if (this.isCreate) this.initCreateTodo(eventName);
        else this.initEditTodo(taskData);
    };

    initAssign = (taskData: EventTaskData) => {
        const hasApproveDenyPerms = this.initNotTodo(taskData);
        const type = S25Util.propertyGetVal(this.taskNode, "approval_type_id");
        const objectId = S25Util.propertyGetVal(this.taskNode, "object_id");
        const objectType = S25Util.propertyGetVal(this.taskNode, "object_type");

        const reservations: any = {};
        const profiles = S25Util.propertyGet(taskData, "profile") || [];
        for (let profile of profiles) {
            for (let res of profile.reservation) reservations[res.reservation_id] = res.reservation_start_dt;
        }

        const approvals = [];
        for (let approval of taskData.approval) {
            const correctType = approval.approval_type_id === type;
            const correctObject = approval.object_id === objectId && approval.object_type === objectType;
            if (!correctType || !correctObject) continue;

            const user = approval.approval_contact?.find((contact: any) => contact.approval_contact_id === this.userId);
            const approvalState = user?.[0]?.approval_contact_state;
            approvals.push(approval);

            this.repeatedElements.push({
                taskId: approval.approval_id,
                eventDate: S25Util.date.getDate(reservations[approval.approval_profile_id] || taskData.start_date),
                dueDate: {
                    //date, taskId, isTodo, taskCount, eventId, objectType, objectId, assignedToId, assignedById
                    date: S25Util.date.getDate(S25Util.propertyGet(approval, "respond_by") || new Date()),
                    taskId: approval.approval_id,
                    assignedToId: hasApproveDenyPerms ? this.userId : null,
                    assignedById: parseInt(approval.assigned_to_id),
                    eventId: this.data.eventId,
                    callback: this.dueDateCallback,
                },
                comment: {
                    value: S25Util.propertyGetVal(approval, "approval_comments"),
                    taskId: approval.approval_id,
                    assignedToId: hasApproveDenyPerms ? this.userId : null,
                    assignedById: parseInt(approval.assigned_to_id),
                    eventId: this.data.eventId,
                    callback: this.commentCallback,
                },
                taskAction: TaskActionCellFactory.generateActionCell(
                    {
                        itemCount: 1,
                        getRow: this.data.listModelBean.getRow,
                        editCell: this.data.listModelBean.editCell,
                        getCell: this.data.listModelBean.getCell,
                        itemStateId: approvalState || S25Util.propertyGetVal(approval, "approval_state"),
                        itemTypeId: this.data.taskActionsCell.itemTypeId,
                        assignedToId: hasApproveDenyPerms ? this.userId : null,
                        assignedById: parseInt(approval.assigned_to_id),
                        requesterId: this.data.taskActionsCell.requesterId,
                        eventId: this.data.eventId,
                        itemId: approval.approval_id,
                        itemName: this.taskNameBean.value,
                        callback: this.taskActionCallback,
                    },
                    this.data.listModelBean,
                ),
            });
        }

        // Approve/deny all
        if (!approvals.length) return;
        this.taskActionAll = TaskActionCellFactory.generateActionCell(
            {
                itemCount: approvals.length,
                objectType,
                objectId,
                itemId: S25Util.propertyGetVal(this.taskNode, "approval_id"),
                getRow: this.data.listModelBean.getRow,
                editCell: this.data.listModelBean.editCell,
                getCell: this.data.listModelBean.getCell,
                itemStateId: S25Util.propertyGetVal(this.taskNode, "approval_state"),
                itemTypeId: this.data.taskActionsCell.itemTypeId,
                assignedToId: hasApproveDenyPerms ? this.userId : null,
                assignedById: parseInt(this.taskNode.assigned_to_id),
                requesterId: this.data.taskActionsCell.requesterId,
                eventId: this.data.eventId,
                itemName: this.taskNameBean.value,
                callback: this.getTaskActionAllCallback(approvals),
            },
            this.data.listModelBean,
        );
    };

    initFYI = (taskData: EventTaskData) => {
        if (!this.taskNode)
            this.taskNode = S25Util.propertyGetParentWithChildValue(taskData, "approval_id", this.data.taskId);
        const hasApproveDenyPerms = this.initNotTodo(taskData);
        this.repeatedElements.push({
            taskId: this.data.taskId,
            dueDate: {
                //date, taskId, isTodo, taskCount, eventId, objectType, objectId, assignedToId, assignedById
                date: S25Util.date.getDate(S25Util.propertyGet(this.taskNode, "respond_by") || new Date()),
                taskId: this.data.taskId,
                assignedToId: hasApproveDenyPerms ? this.userId : null,
                eventId: this.data.eventId,
                callback: this.dueDateCallback,
            },
            comment: {
                value: S25Util.propertyGetVal(this.taskNode, "approval_comments"),
                taskId: this.data.taskId,
                assignedToId: hasApproveDenyPerms ? this.userId : null,
                eventId: this.data.eventId,
                callback: this.commentCallback,
            },
            taskAction: TaskActionCellFactory.generateActionCell(
                {
                    itemCount: 1,
                    getRow: this.data.listModelBean.getRow,
                    editCell: this.data.listModelBean.editCell,
                    getCell: this.data.listModelBean.getCell,
                    itemStateId: this.data.taskActionsCell.itemStateId,
                    itemTypeId: this.data.taskActionsCell.itemTypeId,
                    assignedToId: hasApproveDenyPerms ? this.userId : null,
                    eventId: this.data.eventId,
                    itemId: this.data.taskId,
                    itemName: this.taskNameBean.value,
                    callback: this.taskActionCallback,
                },
                this.data.listModelBean,
            ),
        });
        this.getApprovalStatuses();
        this.changeDetector.detectChanges();
    };

    initEditTodo = (taskData: TodoTaskData) => {
        this.assignedById = parseInt(S25Util.propertyGet(taskData, "assigned_by_id"));
        this.assignedByName = S25Util.propertyGet(taskData, "assigned_by_name") || "";
        this.eventName = S25Util.propertyGet(taskData, "object_name") || false;
        this.data.eventId = parseInt(S25Util.propertyGet(taskData, "object_id"));
        this.assignedToId = parseInt(S25Util.propertyGet(taskData, "assigned_to_id"));
        this.multiSelectBeanTo.selectedItems = [];
        if (this.assignedToId) {
            const itemName = S25Util.propertyGet(taskData, "assigned_to_name") || "";
            this.multiSelectBeanTo.selectedItems = [{ itemId: this.userId, itemName, checked: true }];
            this.assignedToId === this.userId || this.assignedById === this.userId
                ? (this.isCommentreadOnly = false)
                : (this.isCommentreadOnly = true); //ANG-4596
        }

        this.repeatedElements.push({
            taskId: this.data.taskId,
            dueDate: {
                date: S25Util.date.getDate(S25Util.propertyGet(taskData, "due_date") || new Date()),
                taskId: this.data.taskId,
                isTodo: true,
                assignedToId: this.assignedToId,
                assignedById: this.assignedById,
                callback: this.dueDateCallback,
            },
            comment: {
                value: S25Util.propertyGetVal(taskData, "comment"),
                isTodo: true,
                taskId: this.data.taskId,
                assignedToId: this.assignedToId,
                callback: this.commentCallback,
            },
            taskAction: TaskActionCellFactory.generateActionCell(
                {
                    itemCount: 1,
                    getRow: this.data.listModelBean.getRow,
                    editCell: this.data.listModelBean.editCell,
                    getCell: this.data.listModelBean.getCell,
                    itemStateId: this.data.taskActionsCell.itemStateId,
                    itemTypeId: this.data.taskActionsCell.itemTypeId,
                    todoSubType: S25Util.propertyGet(taskData, "todo_subtype"),
                    isTodo: true,
                    todoType: this.data.taskActionsCell.todoType,
                    assignedToId: this.assignedToId,
                    eventId: this.data.eventId,
                    itemId: this.data.taskId,
                    itemName: this.taskNameBean.value,
                    callback: this.taskActionCallback,
                },
                this.data.listModelBean,
            ),
        });
        this.changeDetector.detectChanges();
    };

    initCreateTodo = (eventName: string) => {
        this.multiSelectBeanTo.selectedItems = [{ itemId: this.userId, itemName: this.userName, checked: true }]; // Default assigned-to to current user
        this.assignedById = this.userId;
        this.assignedByName = this.userName;
        this.eventName = eventName || false;
        this.date = new Date();
        this.changeDetector.detectChanges();
    };

    initNotTodo = (taskData: EventTaskData) => {
        this.taskNode = S25Util.propertyGetParentWithChildValue(taskData, "approval_id", this.data.taskId);
        if (this.taskNode === null) this.taskNode = taskData?.approval[0]; // adding this for task calendar view
        const hasApproveDenyPerms = TaskService.hasApproveDenyPerms(this.taskNode, this.userId);
        this.taskNameBean.value = S25Util.propertyGet(this.taskNode, "approval_name") || "";
        this.eventName = S25Util.propertyGet(taskData, "event_name") || false;
        this.isCommentreadOnly = !hasApproveDenyPerms; //ANG-4596
        return hasApproveDenyPerms;
    };

    initInfiniteScroll = () => {
        let working = false;
        const pageSize = 10;
        let pagesShown = 1;

        this.scrollHasMorePages = () => pageSize * pagesShown < this.repeatedElements.length;
        this.infiniteScrollAction = async (callback?: () => void) => {
            if (!working && this.scrollHasMorePages()) {
                working = true;
                await S25Util.delay(0);
                this.actionsShown = pageSize * ++pagesShown;
            }
            callback?.();
            working = false;
        };
    };

    getData = () => {
        const { eventId, taskId } = this.data;
        const dataPromise = this.isTodo
            ? taskId && TaskService.getTodo(taskId)
            : eventId && EventService.getEventInclude(eventId, this.eventIncludes, null, true);
        const eventNamePromise =
            this.isTodo && eventId && !this.isCreate && EventService.getEventName(this.data.eventId);

        return Promise.all([
            ContactService.getCurrentName(),
            ContactService.getCurrentId(),
            dataPromise,
            eventNamePromise,
            UserprefService.getS25Dateformat(),
            UserprefService.getS25Timeformat(),
        ]).then(([userName, userId, taskData, eventName, dateFormat, timeFormat]) => {
            return { userName, userId, taskData, eventName, dateFormat, timeFormat };
        });
    };

    getTitle = (taskType: Task.Id, isCreate: boolean) => {
        if ([Task.Ids.FYI, Task.Ids.Authorization].includes(taskType)) this.data.title = "Edit Notification Task";
        else if ([Task.Ids.Assign, Task.Ids.UnAssign].includes(taskType)) this.data.title = "Edit Assignment Request";
        else if (isCreate) this.data.title = this.taskLang.text.create_todo;
        else this.data.title = this.taskLang.text.edit_todo;
    };

    /**
     * Finds all the contacts associated with the current task
     * adds them to this.approvalState with their task status authorized, in progress etc.
     */
    getApprovalStatuses = () => {
        const messages: { [key: string]: { id: number; name: string }[] } = {};

        const taskData = this.taskData as EventTaskData;
        for (let approval of taskData.approval) {
            if (approval.object_type === this.taskNode.object_type && approval.object_id === this.taskNode.object_id) {
                for (let contact of approval.approval_contact || []) {
                    const status = `${contact.notification_type_name} ${contact.approval_contact_state_name
                        .split("/")
                        .join("/ ")}`;
                    messages[status] ??= [];
                    messages[status].push({ id: contact.approval_contact_id, name: contact.approval_contact_name });
                    if (taskData.notify_type_id == 2) {
                        if (contact.notification_type_id == 2 && !this.data.showAllApproveMsg)
                            this.data.showAllApproveMsg = true;
                        this.data.showAllApproveMsg = !!this.data.showAllApproveMsg;
                    } else this.data.showAllApproveMsg = false;
                }
            }
        }

        this.setApprovalState(messages);
    };

    setApprovalState(states: Record<string, { id: number; name: string }[]>) {
        const approvalState = Object.entries(states).map(([state, contacts]) => ({
            state,
            contacts: contacts.sort((a, b) => a.name?.localeCompare?.(b.name) ?? 1),
        }));
        approvalState.sort((a, b) => a.state.localeCompare(b.state));
        this.approvalState = approvalState;
    }

    editTaskName = async (id: number, name: string) => {
        if (name === "") return;
        if (!this.isTodo || this.isCreate) return;
        return TaskService.putTodoName(id, name.trim()).then(() => {
            this.editCell({ itemName: name }, "todo", id, "task_item");
            // Must also edit origItemName bc it is used to set # requests in names and ends up overwriting itemName
            this.editCell({ origItemName: name }, "todo", id, "task_item");
            this.successF();
        }, this.errorF);
    };

    successF = async () => {
        this.resultMsg = "";
        await S25Util.delay(250);
        this.resultMsg = "Success saving data at " + S25Datefilter.transform(new Date(), this.timeFormat);
        this.changeDetector.detectChanges();
        this.data.editCompCallBack?.();
    };

    errorF = async () => {
        this.resultMsg = "";
        await S25Util.delay(250);
        this.resultMsg = "Error saving data at " + S25Datefilter.transform(new Date(), this.timeFormat);
        this.changeDetector.detectChanges();
    };

    editCell = (data: any, type: string, id: number, prefName: string, extend = true, refresh = false) => {
        this.data.listModelBean.editCell(data, this.data.listModelBean.getRow(type + id), prefName, extend, refresh);
    };

    dueDateCallback = (taskId: number, dueDate: Date) => {
        this.editCell({ date: dueDate }, this.isTodo ? "todo" : "task", taskId, "respond_by");
    };

    commentCallback = (taskId: number, comment: string) => {
        this.editCell({ value: comment }, this.isTodo ? "todo" : "task", taskId, "comments");
    };

    taskActionCallback = (callback: () => void) => {
        S25Util.refreshNgFor(this, "repeatedElements");
        this.successF();
        this.data.editCompCallBack?.(); //worflow, need to call back and refresh the data
        if (S25Util.isFunction(callback)) callback();
    };

    stateChange(e: number) {
        this.successF();
    }

    getTaskActionAllCallback = (approvals: any[]) => {
        return (itemStateId: any, itemName: string, contacts: any[]) => {
            // Set in taskAction callback, eg: scope.modelBean.callback(newState, ...);
            // update all underlying tasks with overall new state, name, and contacts
            for (let approval of approvals) {
                const row = this.data.listModelBean.getRow("task" + approval.approval_id);
                this.data.listModelBean.editCell({ itemStateId }, row, "task_item", true, false);
                this.data.listModelBean.editCell({ itemStateId }, row, "actions", true, false);
                const assignedToCell = this.data.listModelBean.getCell(row, "assigned_to"); // Get assigned to cell
                const assignedToTask = assignedToCell && assignedToCell.task; // Get task listed on that cell
                // Update contacts array in assigned_to cell to the new array values returned from the service call
                if (contacts && assignedToTask?.approval_contact) {
                    assignedToTask.approval_contact = contacts;
                    this.data.listModelBean.editCell({ task: assignedToTask }, row, "assigned_to", true, false);
                }
                // Status is updated to overall state id so users can still see the overall state
                this.data.listModelBean.editCell({ itemStateId, itemName }, row, "status", true, false);
            }
            this.taskActionCallback(this.close);
            this.data.editCompCallBack?.(); //worflow, need to call back and refresh the dataset
        };
    };

    close = () => {
        this.data.closeModal();
    };

    createTodo = () => {
        if (!this.validate()) return;
        S25LoadingApi.init(this.elementRef.nativeElement);
        const taskItem = {
            eventId: this.data.eventId,
            taskName: this.taskNameBean.value,
            taskComment: this.taskComment,
            dueDate: this.date,
            assignedById: this.assignedById,
            assignedToId: this.multiSelectBeanTo.selectedItems[0].itemId,
        };
        if (this.isCreate && !this.data.eventId) {
            return TaskService.createPlainTodo(taskItem)
                .then((isSuccess) => {
                    if (!isSuccess) return;
                    this.inProgress = false;
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.changeDetector.detectChanges();
                })
                .catch(function (err) {
                    S25Util.showError(err);
                });
        } else if (this.isCreate && this.data.eventId) {
            TaskService.createEventTodo(taskItem).then((isSuccess) => {
                if (isSuccess) {
                    this.inProgress = false;
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    this.changeDetector.detectChanges();
                }
            });
        }
    };

    validate = () => {
        let isValid = true;
        if (!this.taskNameBean.value) {
            alert(this.taskLang.text.missing_name);
            isValid = false;
        } else if (this.taskNameBean.value.length > 40) {
            alert("Task name must be 40 characters or less");
            isValid = false;
        } else if (this.multiSelectBeanTo.selectedItems.length === 0) {
            alert(this.taskLang.text.missing_contact);
            isValid = false;
        } else if (!S25Util.date.isValid(this.date)) {
            alert(this.taskLang.text.missing_date);
            isValid = false;
        }
        return isValid;
    };

    createAnotherTodo = async () => {
        this.taskNameBean.value = "";
        this.taskComment = "";
        this.date = S25Util.date.getDate(new Date());
        this.multiSelectBeanTo.selectedItems = [{ itemId: this.userId, itemName: this.userName, checked: true }]; // Default assigned-to to current user
        this.inProgress = await S25Util.delay(0, true);
        this.changeDetector.detectChanges();
    };

    editAssignedTo = () => {
        const selected = this.multiSelectBeanTo.selectedItems?.[0];
        if (!this.isTodo || this.isCreate || !selected || this.assignedToId === parseInt(selected.itemId)) return;
        this.assignedToId = selected.itemId;
        const newName = TaskService.formSingleContactString(selected.itemName, String(this.assignedToId), this.userId);
        return TaskService.putTodoAssignment(this.data.taskId, selected.itemId).then(() => {
            this.editCell(newName, "todo", this.data.taskId, "assigned_to", false);
            this.successF();
        }, this.errorF);
    };

    editTaskComment = async (value: string) => {
        let putPromise: Promise<any>;
        if (this.isTodo) putPromise = TaskService.putTodoComment(this.data.taskId, value);
        else putPromise = TaskService.putEventTaskComment(this.data.eventId, this.data.taskId, value);
        const [_, err] = await S25Util.Maybe(putPromise);
        if (err) return this.errorF();
        this.successF();
        this.data.callback?.(this.data.taskId, value);
    };

    editDueDate = async (date: Date) => {
        const finish = () => this.data.callback?.(this.data.taskId, date);
        let putPromise: Promise<any>;
        if (this.isTodo) putPromise = TaskService.putTodoDueDate(this.data.taskId, date);
        else if (this.data.taskCount > 1) {
            // Set all tasks with matching approvalType, objectType, and objectId, to the new date
            const eventData = await EventService.getEventInclude(this.data.eventId, ["workflow"]);
            const approvals = S25Util.propertyGet(eventData, "approval");
            if (!approvals) return finish();
            const approvalType = parseInt(
                S25Util.propertyGetVal(
                    S25Util.propertyGetParentWithChildValue(approvals, "approval_id", this.data.taskId),
                    "approval_type_id",
                ),
            );
            if (!approvalType) return finish();
            approvals.map((item: any) => {
                if (
                    item &&
                    parseInt(item.approval_type_id) === approvalType &&
                    parseInt(item.object_type) === parseInt(this.data.objectType) &&
                    parseInt(item.object_id) === parseInt(this.data.objectId)
                ) {
                    item.status = "mod";
                    item.respond_by = S25Util.date.toS25ISODateStrEndOfDay(date);
                }
            });
            eventData.status = "mod";
            putPromise = EventService.putEvent(this.data.eventId, { events: { event: eventData } });
        } else {
            putPromise = TaskService.putEventTaskDueDate(this.data.eventId, this.data.taskId, date);
        }
        const [_, err] = await S25Util.Maybe(putPromise);
        if (err) return this.errorF();
        this.successF();
        finish();
    };

    /*
    To be run once new contacts are added to the task.
    NP Auth - adds new contacts to "authorization in progress list"
    NP FYI - adds new contacts to "FYI in progress list"
    AP - adds to "Assigned To" list
     */
    contactsChange = (data: { added: S25ItemI[]; removed: S25ItemI[] }) => {
        if (!data) return;
        const removed = new Set(data.removed.map((item) => item.itemId));

        if ([Task.Ids.FYI, Task.Ids.Authorization].includes(this.data.taskType)) {
            // Remove contacts
            for (const state of this.approvalState) {
                state.contacts = state.contacts.filter((contact) => !removed.has(contact.id));
            }
            // Add contacts
            const states = new Map(this.approvalState.map((state) => [state.state, state.contacts]));
            for (const added of data.added) {
                const stateName = typeNames[added.notifyType];
                let contacts = states.get(stateName);
                if (!contacts) {
                    contacts = [];
                    states.set(stateName, contacts);
                    this.approvalState.push({ state: stateName, contacts });
                }

                contacts.push({ id: Number(added.itemId), name: added.itemName });
            }
        } else if ([Task.Ids.Assign, Task.Ids.UnAssign].includes(this.data.taskType)) {
            // Remove contacts
            this.taskNode.approval_contact = this.taskNode.approval_contact.filter(
                (contact) => !removed.has(Number(contact.approval_contact_id)),
            );
            // Add contacts
            for (const added of data.added) {
                this.taskNode.approval_contact.push({
                    approval_contact_id: Number(added.itemId),
                    approval_contact_name: added.itemName,
                    approval_contact_state: 1,
                } as Event.Workflow.Contact);
            }
        }
        this.changeDetector.detectChanges();
    };

    @Bind
    async removeAssignee(item: S25ItemI) {
        const resp = await TaskEditService.microUpdate([this.data], { unassignContact: [item] }, this.data.eventId);
        const contacts = resp.data[0].contacts.map((c) => ({ id: c.contId, name: c.contName, type: c.notifyType }));
        const groups = S25Util.array.groupBy(contacts, (c) => typeNames[c.type]);
        this.setApprovalState(groups);
    }
}

type TaskData = TodoTaskData | EventTaskData;
type TodoTaskData = Awaited<ReturnType<typeof TaskService.getTodo>>;
type EventTaskData = Awaited<ReturnType<typeof EventService.getEventInclude>>;
