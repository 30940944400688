/* s25TaskworkflowCompoent is running issues call S25-ng-opt, so create this compoent to work around with it */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { OptUtil } from "../s25-opt/s25.opt.util";
import { TaskWorkflowApi } from "../s25-task/s25.task.workflow.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25OptComponent } from "../s25-opt/s25.opt.component";
import { TaskListService } from "../../services/task/task.list.service";
import { ContactService } from "../../services/contact.service";
import { jSith } from "../../util/jquery-replacement";
import { Api } from "../../api/api";
import { EventTaskWorkListApi, TaskViewModelI } from "../s25-task/s25.event.task.list.component";
import { Task } from "../../pojo/Task";

export class TaskViewApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        return Api.callApiFn(relativeElem, "s25-ng-task-view", null, null, (comp: S25TaskViewComponent) => {
            comp.ngOnInit();
        });
    }

    static reset(relativeElem: HTMLElement) {
        return Api.callApiFn(relativeElem, "s25-ng-task-view", null, null, (comp: S25TaskViewComponent) => {
            comp.resetF();
        });
    }
}

@TypeManagerDecorator("s25-ng-task-view")
@Component({
    selector: "s25-ng-task-view",
    template: `
        @if (this.init) {
            <div id="taskview" class="c-margin-left--half c-margin-top--half  c-margin-bottom--half ">
                @if (noResultMessage === "") {
                    <span class="task-workflow--wrapper">
                        <s25-ng-opt [modelBean]="modelBean.optBean" (modelValueChange)="onChange($event)"></s25-ng-opt>
                    </span>
                }
                @if (modelBean.workflowChained && modelBean.getRow && modelBean.getRow.length > 0) {
                    <span>
                        <s25-ng-task-workflow-view [modelBean]="modelBean"></s25-ng-task-workflow-view>
                    </span>
                }
                @if (!modelBean.workflowChained && modelBean.getRow && modelBean.getRow.length > 0) {
                    <span>
                        <s25-ng-event-task-list [modelBean]="modelBean"></s25-ng-event-task-list>
                    </span>
                }
                @if (noResultMessage !== "") {
                    <div class="ngListNoResults">{{ noResultMessage }}</div>
                }
                @if (noAssignedToYouMessage !== "") {
                    <div class="ngListNoResults">{{ noAssignedToYouMessage }}</div>
                }
            </div>
        }
    `,
    styles: [
        ".drop-shadow { -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7)); filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));} .task-workflow--wrapper { position: relative;}",
    ],

    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25TaskViewComponent implements OnInit {
    @Input() modelBean: TaskViewModelI;
    @Input() viewMode?: any; // use later when we switch non work flow event list to TS
    init: boolean = false;
    currentUserId: number;
    noResultMessage: string;
    noAssignedToYouMessage: string;

    @ViewChild(S25OptComponent) optComponent: S25OptComponent;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.zone.run(() => {
            this.getOptBean().then((resp) => {
                this.getData().then((isInit) => {
                    this.init = isInit;
                    this.cd.detectChanges();
                });
            });
        });
    }

    async onChange(e: any) {
        this.modelBean.optBean = this.optComponent.modelBean;

        let modelBean: any = { modelBean: this.modelBean };
        let data = await TaskListService.getEventTasks(modelBean);
        if (parseInt(this.optComponent.modelBean.taskType) === 2) {
            let ret: any = [];
            jSith.forEach(data, (key, c) => {
                let find: any;
                if (c.assignedTo.task) {
                    find = c.assignedTo.task.approval_contact?.find((f: any) => {
                        return c && parseInt(f.approval_contact_id) === this.currentUserId;
                    });
                } else if (c.actions.isTodo) {
                    find = parseInt(c.actions.assignedToId) === this.currentUserId;
                }
                find ? ret.push(c) : "";
                this.modelBean.getRow = ret;
                return ret;
            });

            if (this.modelBean.getRow.length > 0) {
                this.noAssignedToYouMessage = "";
            } else {
                this.noAssignedToYouMessage = "The event has no associated tasks assigned to you.";
            }
        } else {
            this.modelBean.getRow = data as Task.TaskListI[];
            this.noAssignedToYouMessage = "";
        }

        this.modelBean.workflowChained
            ? TaskWorkflowApi.refresh(this.elementRef.nativeElement)
            : EventTaskWorkListApi.refresh(this.elementRef.nativeElement);
    }

    async getOptBean() {
        let optBeanData = await OptUtil.formEventTaskListBean(this.refreshF, this.modelBean.optBeanRunTime);
        this.modelBean.optBean = optBeanData;
        return optBeanData;
    }

    async getData() {
        await this.getCurrentUser();
        let modelBean: any = { modelBean: this.modelBean };
        const data = await TaskListService.getEventTasks(modelBean);
        // if (err) {
        //     console.log("error", err);
        // }
        if (data && data.length > 0) {
            jSith.forEach([...data], (_, d) => {
                d.currentUserId = this.currentUserId;
            });
            this.noResultMessage = "";
        } else {
            this.noResultMessage = "The event has no associated tasks.";
        }
        this.modelBean.getRow = data as Task.TaskListI[];
        return true;
    }

    async getCurrentUser() {
        return (this.currentUserId = await ContactService.getCurrentId());
    }

    async resetF() {
        this.init = false;
        this.cd.detectChanges();
        await S25Util.delay(250);
        this.ngOnInit();
    }

    refreshF() {
        // using TS and output get update data, so no need to do anything here.
    }
}
